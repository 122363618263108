.orderBoxTable {
    overflow-x: hidden;
    height: 400px;

    &__cmpOrder {
        color: #337ab7;
    }

    &__addOrder {
        color: #337ab7;
        cursor: pointer;
    }

    .label {
        margin-top: 3px;
    }
    a {
        text-decoration: none;
        cursor: pointer;
    }
}

.OrderHistory {
    .label {
        margin-bottom: 0px;
    }
    .offcanvas-body {
        hr {
            margin-top: 0;
        }
    }

    .search {
        padding: 5px;
        width: 100%;
        border: 0.2px solid #c8c9cb;
    }

    .secRow {
        position: relative;
    }

    .searchIcon {
        position: absolute;
        right: 20px;
        top: 35px;
    }

    .addOrder {
        color: #58666e;
        font-size: 14px;
        font-weight: 600;

        p {
            font-weight: 500;
            font-size: 15px;
            margin-bottom: 2px;
            color: #6e6d6c;
        }
    }
}
