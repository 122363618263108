.OrderHistory {
    width: 40% !important;
    $primaryColor: #898784;

    .orderModalTable {

        table th,
        table td {
            padding: 20px 15px;
        }
    }

    .orderRecivedTable {
        table {
            tr:nth-child(even) {
                background-color: #f2f2f2;
            }

            tr:nth-child(odd) {
                background-color: #ffffff;
            }

            th {
                background: none;
                padding: 20px 15px;
            }
        }
    }

    .orderModalHeader {
        display: flex;
        justify-content: space-around;
        align-items: baseline;

        .OrderStatus {
            text-align: center;

            h5 {
                color: #898784;
            }

            p {
                margin-bottom: 5px;
                color: #f5977d;
                font-style: italic;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .OrderNumber {
            text-align: center;

            h5 {
                color: #898784;
            }

            p {
                margin-bottom: 5px;
                font-style: italic;
                font-weight: 500;
                font-size: 14px;
            }
        }

        h5 {
            margin: 0;
        }
    }

    .NewBtn {
        text-align: center;
        color: #333dff;
    }

    .deliverBtn {
        color: #eb1d5d;
        text-align: center;
        padding: 0 5px;
    }

    .shippedBtn {
        color: #bc04b7;
        text-align: center;
    }

    .invoicedBtn {
        color: #00afec;
        text-align: center;
    }

    .paidBtn {
        color: #00a453;
        text-align: center;
    }

    .orderNum {
        color: #569c22;
    }

    .AddOrderBtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .orderDetailbtn {
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
        padding: 5px 7px;
        background-color: #1c2b36;
        width: max-content;

        &:focus {
            background-color: #1c2b36;
            -color: #1c2b36;
        }

        .infoIcon {
            margin-right: 5px;
            font-size: 16px;
        }
    }

    &__printBtn {
        cursor: not-allowed !important;
        opacity: 0.5;
    }

    &__tableItem {
        color: #337ab7;
        cursor: pointer;
    }

    .title {
        span {
            font-size: 1.5rem;
        }

        color: $primaryColor;
        font-weight: 400;
    }

    table th {
        text-transform: none;
        background-color: none !important;
        color: #58666e;
        text-wrap: nowrap;
    }

    table {
        .dispatched {
            background: #7bb902 !important;
            border: 0;
            color: #000 !important;
            border-radius: 2px;
        }

        .delivered {
            background: #7bb902 !important;
            border: 0;
            color: #000 !important;
            border-radius: 2px;
        }

        .invoiceDownload {
            cursor: pointer;
        }
    }
}

.removeButton {
    z-index: 10000;
    color: #337ab7;
    cursor: pointer;
}

@media (max-width: 768px) {
    .OrderHistory.offcanvas {
        width: 90% !important;
    }
}