// Details Modal
// background-color: orange;
// width: 50%;
// /* min-height: 15%; */
// margin-left: 88px;
.Main_head {
    .Main_Content {
        .card.Image_part {
            display: flex;
            margin-bottom: 10px;
            padding: 10px;
            flex-direction: row;
            .img {
                width: 25%;
                .image {
                    border: 1px solid orange;
                    width: 100%;
                }
                .card-body {
                    text-align: center;
                    background-color: orange;
                    padding: 2%;
                }
            }
            .Details {
                padding: 15px;
                color: #5d666e;
                width: 75%;
                .commonText {
                    font-weight: 300;
                }
                .orderId {
                    font-weight: 300;
                }
            }
        }
    }
}
// Program Css
.commonHeaderTitle,
.programModelTitle h3 {
    font-size: 24px;
    color: #58666e;
    font-weight: 400;
    margin: 0;
}
@media (max-width: 768px) {
    .programModel {
        width: 80% !important;
    }
}
@media (min-width: 1200px) {
    .Main_head {
        width: 40% !important;
    }
}

// program Modal
.Program_table {
    .ViewQR_Code {
        color: #337ab7;
        cursor: pointer;
    }
    .Active_Inactive {
        color: green;
    }
}

// Category Modal
.Catergory_ModalHead {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
