.Program_slider {
    width: 40%;
    &__uploadBtn {
        border: none;
        outline: none;
        padding: 0 1rem;
        line-height: 36px;
        background: #27c24c;
        border-color: #27c24c;
        cursor: pointer;
    }

    &__rest_checkbox {
        margin: 5px;
    }
}
.sliderTwo {
    width: 50% !important;

    .secProgramSlider {
        max-width: 80%;
        margin: 0 20%;
        border: 1px solid black;
        padding: 15px;
        label {
            font-weight: 600;
            color: #000;
            font-size: 14px;
        }
        .form-label {
            margin-top: 10px;
        }
    }
}

@media (max-width: 768px) {
    .sliderTwo {
        width: 90% !important;
        .offcanvas-header {
            flex-direction: column;
            margin-bottom: 10px;
        }
        .secProgramSlider {
            margin: 0;
            max-width: 100%;
        }
    }
}
