.customSlider {
    &__model {
        color: #ffffff;
        background-color: #1c2b36 !important;
        border-color: #1c2b36;
        margin-right: 5%;
        margin-bottom: 5px;
    }

    &__title {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.offcanvas-header {
    margin-top: 10px;
    .customSlider__title {
        color: #58666e;
        font-size: 24px;
    }
}

.dropStationModal {
    .offcanvas-body {
        hr {
            margin: 0;
        }
        div {
            margin-top: 10px;

            label {
                margin: 10px 0;
            }
        }
    }
}
