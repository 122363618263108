.Claims {
    .Content {
        .main_section {
        }
    }
    .Add_Btn {
        display: flex;
        flex-direction: row-reverse;
    }

    table td {
        padding: 10px;
        border-top: 0;
        border-bottom: 0;
    }

    table tr:nth-child(even) {
        background-color: #f3f3f3;
    }
}

@media (max-width: 1370px) {
    .Claims {
        .Content {
            .main_section {
                min-height: 85vh;
            }
        }
    }
}
