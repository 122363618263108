.pending-sec {
    .boxIcon {
        width: 25px;
        margin: 8px;
        height: 25px;
    }

    .pendingDiv {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 2%;
    }

    .pendingBtn {
        margin-top: -50px;
        margin-bottom: 10px;
        margin-right: 2%;
    }
}