.wrh {

    &__model {
        color: #ffffff;
        background-color: #1c2b36 !important;
        border-color: #1c2b36;
        margin-right: 5%;
        margin-bottom: 5px;
    }

    &__title {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .disabledContent {
        cursor: not-allowed;
        background-color: rgb(229, 229, 229) !important;
    }


}

.pac-container {
    background-color: #FFF;
    z-index: 9999;
    position: fixed;
    display: inline-block;
    float: left;
}

.modal {
    z-index: 2000;
}

.modal-backdrop {
    z-index: 1000;
}