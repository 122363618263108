.parrent__table {
    min-height: 80vh;

    .table {
        border-collapse: collapse;
        font-size: 14px;

        &__header {
            background-color: #f7f7f7;
        }

        &__editButton>div {
            &>button {
                color: #ffffff;
                background-color: #1c2b36;
                border-color: #1c2b36;
                padding: 3px 12px;
                border-radius: 2px;
                border: none;
            }
        }

        &__editDiv {
            background-color: red !important;
            display: flex;
            justify-content: flex-end;
        }

        &__heading {
            cursor: pointer;
            font-weight: bold;
            padding: 10px 12px;
            text-align: left;
            white-space: nowrap;
            text-transform: capitalize;

            &:hover {
                background-color: #f0f0f0;
            }

            .table__sort-icon {
                margin-left: 10px;

                .downArrow {
                    width: 10px;
                }
            }
        }

        .wr__edit_btn {
            color: #ffffff !important;
            background-color: #1c2b36;
            border-color: #1c2b36;
        }

        &__row {
            &:nth-child(even) {
                background-color: #f7f7f7;
            }

            &:hover {
                background-color: #f0f0f0;
            }
        }

        &__cell {
            border: 1px solid #e6e6e6;
            padding: 10px 12px;
            text-align: left;
        }

        &__editDiv {
            border: 1px solid #e6e6e6;
            padding: 10px 12px;
            text-align: right;
        }

        &__edit_btn {
            &>div {
                &>button:nth-child(2) {
                    display: none;
                }
            }
        }

        &__editButton>div {
            &>button {
                margin: 0px 5px;
                margin-top: 5px;
            }

            &>button:nth-child(2) {
                display: none;
            }

            &>button:nth-child(4) {
                display: none;
            }
        }

        &__dBtnHide>div {
            &>button:nth-child(3) {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
    .table__pagination__container {
        flex-direction: column;
        margin: 20px 0;

        ul {
            li {
                a {
                    padding: 4px 13px;
                    font-size: 12px;
                }
            }
        }

        .pagination__select {
            margin-top: 10px;
            padding: 7px;
            font-size: 13px;
        }
    }
}

.table__pagination__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 20px;

    .pagination__select {
        margin-left: 20px;
        padding: 9px;
        border: 1px solid #ccc;
        border-radius: 3px;

        &:focus-within {
            outline: 0;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            border-radius: 3px;
            margin: 0 5px;

            a {
                display: block;
                padding: 8px 16px;
                text-decoration: none;
                color: #000;
                border: 1px solid #ccc;
                border-radius: 3px;

                &:hover {
                    background-color: #ccc;
                }
            }

            &.active {
                a {
                    background-color: #1c2b36;
                    color: #fff;
                    border-color: #1c2b36;

                    &:hover {
                        background-color: #1c2b36;
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.itemText {
    font-size: 16px !important;
}