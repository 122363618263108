.station {
    $table-bg-color: #f2f2f2;
    $table-header-bg-color: #d9d9d9;
    $table-header-font-color: #333;
    $table-font-color: #444;

    &__header {
        margin: 10px;
        font-weight: 300;
        font-size: 24px;
    }

    &__accHolder {
        margin: 20px;

        &>* {
            margin: 10px;
        }
    }

    &__row>div {
        margin: 10px;
    }

    &__dbDown>* {
        margin: 8px;
    }

    &__btnAddBox {
        color: #ffffff !important;
        background-color: #1c2b36;
        border-color: #1c2b36;
        border: none;
        border-radius: 0.2rem;
        padding: 5px;
        margin-left: 5px;
    }

    &__rangeSec {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;

        &>div {
            margin: 5px;
        }
    }

    .search_section {
        padding: 10px;

        .SearchAndBtn {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .SerachBar {
                width: 35%;
                padding-left: 25px;
                position: relative;

                .searchIcon {
                    position: absolute;
                    top: 11px;
                    right: 13px;
                    color: #58666e;
                }
            }

            .addStation {
                margin-right: 22px;
            }
        }

        .Inventory__table {
            .Inventory__buttonTable {
                .icons {
                    display: inline;
                    margin-right: 5px;
                }
            }
        }
    }
}

@media (max-width: 414px) {
    table {

        th,
        td {
            padding: 6px;
            font-size: 14px;
        }
    }

    .station {
        .search_section {
            .SearchAndBtn {
                display: block;

                .SerachBar {
                    width: 93%;
                }

                .addStation {
                    width: 50%;
                    font-size: small;
                    /* padding-top: 7px; */
                    padding-bottom: 10px;
                }
            }
        }
    }
}

@media (min-width: 820px) and (max-width: 1180px) {
    .search_section {
        .custmizeTable {
            // min-height: 75vh;
            min-height: 85vh;
        }
    }
}

.QR_Code {
    .disable {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.4;
    }
}

.typeDropdown {
    button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: white;
        color: black;
        align-items: center;
    }
}

.boxType_dropdown {
    .notPrimaryBtn {
        border-color: #ced4da;

        &:hover,
        :active,
        :focus-visible {
            background-color: #fff;
            border-color: #ced4da;
            color: #000;
        }
    }

    button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: white;
        color: black;
        border-radius: 3px;
    }

    .dropdown-menu.show {
        width: 100%;
        border-radius: 3px;
    }
}

.imageModalSection {
    .addInput {
        position: relative;
        width: 100%;

        button {
            background-color: #27c24c;
            color: #000;
            border: 0;
            padding: 6px 12px;
            border-radius: 3px;
        }

        .uploadInput {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }

    .uploadImage {
        text-align: center;
        width: 100%;
        margin-top: 40px;

        img {
            width: 200px;
            height: 200px;
            object-fit: cover;
        }
    }
}