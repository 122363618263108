.Modal_head {
    width: 50% !important;

    .Main_Body {
        .Main_Content {
            .Image_part {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .Details {
                    padding: 15px;
                    text-align: center;

                    p {
                        margin: 0;
                        font-size: 12px;
                    }

                    img.image {
                        width: 55px;
                    }
                }
            }
        }

        .orangePara {
            color: orange;
        }

        .boxClsName {
            margin: 5px;
            padding: 5px;
        }

        .activePara {
            padding: 0 10px;
            font-size: 11px;

            .status {
                font-weight: 700;
            }

            .textStatus {
                font-size: 12px;
            }
        }

        .greenColor {
            color: black;
            border: 1px solid green;
            background-color: green;
        }

        .orangeColor {
            color: black;
            border: 1px solid orange;
            background-color: orange;
        }

        .brownColor {
            color: black;
            border: 1px solid brown;
            background-color: brown;
        }

        .redColor {
            color: black;
            border: 1px solid red;
            background-color: red;
        }
    }
}

@media (max-width: 768px) {
    .offcanvas {
        width: 80% !important;
    }
}