$COLOR: rgb(71, 71, 98);

.custom-accordian{
    font-size: 16px;
    font-weight: bold;


    &__header > button{
        font-size: 16px;
        color:$COLOR;

    }
    .accordion-button:not(.collapsed) {
        color: $COLOR;
        border: none;
        box-shadow: none;
    } 

    

}
.greater-icon{
    color: #3f9b17;
}