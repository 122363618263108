button.accordion-button.collapsed:focus {
    box-shadow: none;
}

.custom-accordian .accordion-button:not(.collapsed) {
    background-color: transparent;
}

.accordion-collapse.collapse.show {
    border-top: 1px solid #dee2e6;
}

/* *{
    box-sizing: border-box;
    outline: 1px solid limegreen !important ;
} */

.Toastify {
    position: relative;
    z-index: 99999 !important;
}
.offcanvas {
    z-index: 9999 !important;
}
.commonModel {
    width: 40% !important;
}

@media (max-width: 768px) {
    .programModel {
        width: 80% !important;
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin-top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bebebe;
}

button.btn-close {
    box-shadow: none;
    font-size: 15px;
}

button.btn-close:focus {
    box-shadow: none;
}

input.form-check-input:focus {
    box-shadow: none;
}

input:focus-visible {
    outline: 0;
}

.userTitle {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 300;
    margin: 0px;
    font-size: 23px;
    padding: 14px 20px;
    padding-top: 11px;
}
