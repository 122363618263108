.Container_type {
    .Add_Btn {
        display: flex;
        flex-direction: row-reverse;
        margin: 8px 28px;
    }
    .boxType_dropdown {
        .notPrimaryBtn {
            border-color: #ced4da;
            &:hover,
            :active,
            :focus-visible {
                background-color: #fff;
                border-color: #ced4da;
                color: #000;
            }
        }
    }
    .btnSection {
        display: flex;
        flex-wrap: wrap;
        .edit {
            padding: 2px;
            margin-right: 3px;
        }
    }
    table th .theadTitleContent .theadTitle {
        padding: 5px;
    }
    table tbody tr td:first-child img {
        width: 45px;
        height: 45px;
        object-fit: cover;
        margin: 6px;
    }
    table td {
        padding: 14px 8px 0 15px;
        vertical-align: top;
    }
    button.Custom__button {
        margin-top: 0 !important;
        padding: 4px 8px;
        width: max-content;
    }
    .adminContainer_table {
        table {
            tr:nth-child(even) {
                background-color: #f3f3f3;
            }
        }
    }
}
.addInput {
    position: relative;
    width: 100%;
    margin-top: 20px;
    button {
        background-color: #27c24c;
        color: #000;
        border: 0;
        padding: 6px 12px;
    }
    .uploadInput {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
    .containerType_image {
        width: 120px;
        height: 120px;
    }
    .Image_Check {
        display: flex;
        justify-content: center;
    }
}
@media (min-width: 360px) and (max-width: 896px) {
    table {
        th,
        td {
            padding: 6px;
            font-size: 14px;
        }
    }
}

@media (min-width: 412px) and (max-width: 915px) {
    .Container_type {
        table {
            th,
            td {
                padding: 6px;
                font-size: 14px;
            }
        }
        .btnSection .Edit_Container .updatew {
            padding: 0px;
            width: 102%;
        }
        .btnSection {
            display: flex;
            flex-wrap: wrap;
            .edit {
                padding: 2px;
            }
        }
    }
}

// iphone 12 and pixel fold and galaxy A31 pe edit button
// surgface duo galcy fold
