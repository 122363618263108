.priceModel {
    $primaryColor: #585555;
    width: 35% !important ;
    .priceModelBody {
        margin: 15px 25px;
        .price {
            font-weight: 600;
            font-size: 14px;
            .price-heading {
                color: #58666e;
                font-size: 14px;
                margin: 0;
            }
        }
    }

    .headerHr {
        margin: 0 2%;
        color: rgb(167, 162, 162);
    }

    .title {
        font-weight: 300;
        color: $primaryColor;
        font-weight: 400;
        color: #585555;
        font-size: 24px;
    }

    .hrLine {
        width: 96%;
        margin: 2% 2% !important;
    }

    .square {
        font-size: 12px;
        font-weight: 400;
        color: $primaryColor;
    }

    input[type='number'] {
        font-size: 12px;
        color: $primaryColor;
    }
}

@media (max-width: 912px) {
    .priceModel {
        width: 70% !important;
        .title {
            font-size: 18px;
        }
        table {
            th,
            td {
                padding: 6px;
                font-size: 14px;
            }
        }
        .priceModelBody {
            margin: 5px 0px;
        }
    }
}
