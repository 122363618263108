.locationComponent {

    .locationDiv {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 2%;
    }

    .locationBtn {
        margin-top: -50px;
        margin-bottom: 10px;
        margin-right: 2%;
    }
}


@media (max-width: 820px) {
    table {

        th,
        td {
            padding: 6px;
            font-size: 14px;
        }
    }
}

@media (max-width: 1370px) {
    .location_table {
        min-height: 85vh;
    }
}