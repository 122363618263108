.headerDiv {
    padding: 0px;
    background-color: #f6f8f8;
    border-bottom: 1px solid #dee5e7;
    color: #58666e;

}

.wrapper-md {
    margin-bottom: 15px;
}

.Inventory__header {
    font-weight: 300;
    font-size: 24px;
    background: #f6f8f8;
}

.Inventory__header p {
    margin: 0;
    color: #58666e;
}