.Program {
    .programeHeaderBtn {
        display: flex;
        justify-content: flex-end;
        margin-right: 2rem;
    }

    .customizeProgramTable {
        table {
            tr {
                td {
                    div {
                        width: max-content;
                    }
                }
            }
        }

        button.Custom__button.ms-2.mb-2 {
            padding: 4px 10px;
            margin: 8px;
        }
    }
}

@media (min-width: 820px) and (max-width: 1368px) {
    .customizeProgramTable {
        min-height: 82vh;
    }
}