.wrh {
    position: relative;
    width: 100%;
    margin-bottom: 5%;
    font-size: 14px;
    overflow-x: hidden;

    p {
        text-align: start;
        font-weight: 300;
        font-size: 24px;
    }

    .AddWarehouseBtn {
        text-align: end;
        .wrh__addBtn {
            color: #ffffff;
            background-color: #1c2b36;
            border-color: #1c2b36;
            margin-bottom: 10px;
            border-radius: 2px;

            :hover > & {
                background-color: #1c2b36;
                border-color: #1c2b36;
            }

            :active > & {
                background-color: #1c2b36;
                border-color: #1c2b36;
            }
        }
    }
    .table__heading {
        font-weight: 600;
    }
    table td {
        padding: 8px;
        border: 0;
    }
    table tbody tr {
        align-items: center;
    }
}
