.addLocation {
    $primaryColor : rgb(28, 43, 54);



    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-family: Arial,
        sans-serif;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 20px;
        color: #333;
    }

    .form-group {
        margin-bottom: 20px;
    }

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: #555;
    }

    .form-control {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        font-size: 14px;
    }

    .radio-group {
        display: flex;
        margin-top: 10px;
        width: 100%;
        margin-bottom: 20px;

        label {
            margin-right: 15px;
            font-weight: normal;
        }
    }



    textarea {
        height: 100px;
    }

    .extra-field,
    .domain-field {
        display: flex;
        align-items: center;

        .remove-btn {
            margin-left: 10px;
            background-color: $primaryColor;
            color: #fff;
            border: none;
            border-radius: 3px;
            cursor: pointer;
            font-size: 12px;
        }
    }

    .add-btn {
        margin-top: 10px;
        background-color: $primaryColor;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
    }

    .submit-btn {
        margin-top: 20px;
        padding: 10px 15px;
        background-color: $primaryColor;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-size: 16px;
    }

    .form-group1 .radio-group {
        margin: 10px 0px !important;
    }

    .form-group1 label {
        margin: 0 !important;
    }

    .form-group1 .text-danger {
        margin: 0px 0px !important;
    }

    div.text-danger {
        font-size: 13px;
    }

    .text-danger {
        margin-left: 4px !important;
    }

    .form-group1 span.text-danger {
        margin-left: 4px !important;
    }

    .viewLocationDetails {
        pointer-events: auto;
    }

}

.readOnly {
    pointer-events: none;
    opacity: 1.4;
}