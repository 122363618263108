.CharityList {
    .Add_Btn {
        display: flex;
        flex-direction: row-reverse;
        .addCharity {
            margin: 2px 25px;
        }
    }
}
.Edit_Charity {
    padding-top: 8px;
    .editIcon {
        padding: 2px;
    }
    .deletIcon {
        padding: 2px;
    }
}
@media (max-width: 768px) {
    .Edit_Charity {
        width: max-content;
        .deleteOne {
            display: flex;
        }
    }
    table {
        th,
        td {
            padding: 6px;
            font-size: 14px;
        }
    }
}
@media (max-width: 1370px) {
    .custmizeCharityTable {
        min-height: 85vh;
    }
}
