.SearchLoc{

    &__inputField{
        width: 100%;
        padding: 0.375rem 0.75rem;;
        border-radius: 0;
        border: 1px solid #ccc;
        &:focus-within{
            outline: 0;
        }
    }
}