.custom-tooltip-container {
    position: relative;
    display: inline-block;

    .custom-tooltip {
        position: absolute;
        background-color: #333;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        z-index: 1000;
        display: none;
        top: 100%;
        left: 0;
        white-space: normal;
        scroll-behavior: smooth;
        max-width: 400px;
        min-height: 20px;

        &.visible {
            display: block;
        }
    }

    .custom-tooltip-trigger {
        cursor: pointer;
    }

    .custom-tooltip-trigger:hover+.custom-tooltip {
        display: block;
    }

}