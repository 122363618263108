.User_category {
    width: max-content;
    display: flex;

    .infoIcon {
        background-color: #75cc34;
        padding: 4px 5px;
        margin: 0 2px;
        cursor: pointer;

        &:hover {
            background-color: #ff494b;
            color: #fff;
        }
    }

    .delete {
        background-color: red;
        padding: 4px 5px;
        margin: 0 4px;
        cursor: pointer;
    }

    .recycleIcon {
        background-color: #fad733;
        padding: 4px 5px;
        margin: 0 2px;
        cursor: pointer;
    }

    i.fa-solid {
        color: #333333;
        padding: 6px 11px;
        border-radius: 3px;
        font-size: 13px;
    }
}

.userTableSec {
    .Total_No_Boxes {
        padding: 10px;
    }

    .perPageItem {
        display: flex;
        width: 50%;
        justify-content: flex-end;
    }
}

.User_table {
    table td {
        color: #58666e !important;
    }

    .Auto_Renewal {
        .auto {
            color: black;
            background-color: #75cc34;
            padding: 3px 11px;
            font-weight: 600;
            color: #333;
            font-size: 14px;
            margin: 5px;

            &:hover {
                background-color: #ff494b;
                color: #fff;
            }
        }
    }
}

.FirstRow {
    display: flex;
    padding: 0px !important;

    .Input_Filed {
        margin-left: 20px;
        margin-top: 15px;
        font-size: 14px;
        padding: 6px 14px !important;
        width: 350px;

        &::placeholder {
            color: #999999;
        }
    }

    .AccoountType_DropDown {
        width: calc(100% - 80%);
        margin-top: 10px;

        .CustomDropdown__toggle {
            color: #999999 !important;
        }
    }
}

@media (max-width: 768px) {
    .pagination_section .table__pagination__container ul li a {
        padding: 2px 8px;
    }
}

@media (min-width: 768px) {
    .AccoountType_DropDown {
        margin-left: 35px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .FirstRow {
        display: flex;
        flex-direction: column;
        margin: 0 15px;

        .Input_Filed {
            margin-top: 15px;
            width: 100%;
            margin-left: 0;
        }

        .AccoountType_DropDown {
            width: 100%;
            margin-top: 10px;
        }
    }

    table {

        th,
        td {
            padding: 6px;
            font-size: 14px;
        }
    }
}

@media (max-width: 375px) {
    .FirstRow .Input_Filed {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .Demo {
        position: relative;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .FirstRow {
        .Input_Filed {
            width: 100%;
        }

        .AccoountType_DropDown {
            width: 30%;
        }
    }

    .pagination_section {
        padding-left: 11%;
    }
}

@media (min-width: 820px) and (max-width: 1180px) {
    .pagination_section {
        padding-left: 60px;
    }
}

@media (min-width: 540) and (max-width: 720px) {
    .FirstRow {
        display: flex;
    }
}

@media (min-width: 280) {
    .FirstRow {
        .Input_Filed {
            width: 100%;
            padding: 0px;
        }

        .AccoountType_DropDown {
            width: 43%;
        }
    }
}

.userDropdown {
    button {
        width: 95%;
        border: 1px solid #dee5e7 !important;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 600;
        padding-left: 17px !important;
        padding-right: 0 !important;
        cursor: unset;
    }
}

.Demo {
    position: relative;

    .searchIcon {
        position: absolute;
        top: 24px;
        right: 0;
        margin-right: 13px;
        font-size: 14px;
        color: rgb(85, 85, 85);
    }
}

.Input_Filed {
    width: 100%;
    border: 1px solid #dee5e7 !important;
    font-size: 14px;
    font-weight: bold !important;
    padding: 5px 6px !important;
    cursor: unset;
}

// button#dropdown- {
//     width: 79%;
//     border: 1px solid #dee5e7 !important;
//     font-size: 14px;
//     margin-bottom: 10px;
//     font-weight: bold;
//     padding: 3px 9px !important;
// }

// fold view

// .FirstRow {
//     display: block;
//     .Input_Filed {
//         margin-top: 15px;
//         width: 100%;
//     }
//     .AccoountType_DropDown {
//         width: 100%;
//         margin-left: 38px;
//         margin-top: 10px;
//     }