.Custom__button {
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    padding: 8px 12px;
    border-radius: 2px;
    border: none;
    background-color: #1c2b36;
    border-color: #1c2b36;
    cursor: pointer;
}

@media (max-width: 768px) {
    .Custom__button {
        font-size: 12px;
    }
    .Inventory__buttonTable {
        & > .Custom__button {
            width: max-content;
        }
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    .Inventory__buttonTable {
        & > .Custom__button {
            width: max-content;
        }
    }
}
