.locationUser {

    .User_table .Auto_Renewal .auto {
        background-color: white;
        color: #877f7f;


        &:hover {
            background-color: #e5e5e5 !important;
            color: #877f7f;

        }
    }

    .autorenewalBox {
        padding: 3px 11px;
        font-weight: 600;
        color: #333;
        font-size: 14px;
        margin: 5px;
    }

}