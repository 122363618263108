.Navbar {
    .dropdown-item:has(> .Charity_list.active) {
        background-color: #131e26;
        width: 100%;
    }

    .navbar-toggler {
        filter: brightness(0) invert(1);
        border: 0;

        &:focus {
            box-shadow: none;
            outline: 0;
            border: 0;
        }
    }

    background-color: #1c2b36;

    &__logo {
        width: 120px;
    }

    &__pAvtar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    a.dropdown-item:active {
        background-color: #adb5bd;
    }

    .Charity_list {
        color: #333;
        text-decoration: none;
        font-weight: 700;
        font-size: 13px;
    }

    .profileDropdown {
        overflow-y: auto;
        max-height: 115px;
    }

    .navbarImage {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40.5px;
        position: relative;

        i.fa-solid.fa-circle {
            color: #27c24c;
            position: absolute;
            border: 2px solid white;
            border-radius: 50%;
            font-size: 7px;
            // font-size: 6px;
            // bottom: -3px;
            // right: 16px;
        }
    }

    .nav-link {
        cursor: pointer;
        color: #869fb1 !important;
        font-weight: 700;
        font-size: 12px;

        .dropdown-menu {
            position: absolute;
            left: -145px;
            top: 45px;
            width: 250px;

            .dropdown-item:focus,
            .dropdown-item:hover,
            .dropdown-item:active {
                background-color: transparent;
                color: #16232d;
            }

            a.dropdown-item {
                color: #7793a7;
                font-weight: 400;
                margin-bottom: 5px;

                &:hover {
                    color: #7793a7;
                }
            }

            hr {
                margin: 5px 0;
            }
        }
    }

    .Navbar__brand {
        .Navbar__logo {
            width: 152px;
            padding: 9px 6px;
        }
    }

    .DropDownItem {
        .nav-link {
            padding: 16px;
            font-size: 13px;

            &:hover {
                background-color: #131e26;
            }

            &::after {
                vertical-align: middle;
            }
        }
    }

    &__link {
        & > a {
            text-decoration: none;
            cursor: pointer;
            color: #869fb1;
            font-weight: 700;
            font-size: 13px;
            // padding: 12px;
            padding: 16px !important;

            &:hover {
                color: #869fb1;
                background-color: #131e26;
            }
        }

        .headerLink.active {
            color: white;
            background-color: #131e26;
            padding: 12px;
            font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial,
                sans-serif;

            &:hover {
                color: #fff;
            }
        }
    }

    .titleName {
        a#basic-nav-dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 400;
            padding: 0;
        }
    }

    // width: 145px;
    // padding-left: 10px;
    &__logout {
        display: flex;
        justify-content: space-between;

        &__drop_first {
            position: relative;

            span {
                font-size: 14px;
                margin: 0 10px;
                color: #7793a7;
            }
        }

        .Navbar__logoutBtn {
            button {
                border: 0 !important;
                padding: 5px 10px;
                border-radius: 5px;
                background: #16232d;
                font-size: 13px;
                color: #96abbb;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    &__aIcon {
        position: absolute;
        bottom: -4px;
        left: 28px;
        color: #27c24c;
        font-size: 10px;
        border: 3px solid #fff;
        border-radius: 50%;
    }

    &__bottom_img {
        width: 40px;
        height: 40px;
        margin-top: 5px;
        // margin-right: 15px;
        cursor: pointer;
        border-radius: 50%;
    }

    // @media (max-width: 768px) {
    //     .Navbar__dropdown_link.DropDownItem.nav-item.dropdown {
    //         align-items: flex-start;
    //         flex-direction: column;
    //     }
    // }
    @media (max-width: 767px) {
        .nav-link {
            .dropdown-menu {
                left: 30px !important;
            }

            .Navbar {
                .navbar-brand {
                    margin-right: 0;
                }

                .navbar-nav {
                    margin-top: 10px;
                    right: 0px;
                }

                .Navbar__profile {
                    position: relative;
                }
            }

            > a {
                padding: 0.5rem 1rem;
            }

            .Navbar__bottom_img {
                position: absolute;
                top: 0px;
                right: 0;
            }
        }
    }

    .active {
        color: white;
        background-color: #131e26;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        .nav-link {
            .dropdown-menu {
                left: 30px !important;
            }
        }

        .Navbar__dropdown_link.DropDownItem.nav-item.dropdown {
            justify-content: flex-start;
        }

        .navbarImage i.fa-solid.fa-circle {
            bottom: 4px !important;
            left: -13px !important;
        }
    }

    @media (max-width: 976px) {
        .Navbar__profile {
            position: relative;
            flex-direction: row;
            justify-content: space-between;

            .navbarImage i.fa-solid.fa-circle {
                bottom: -4px;
                left: -10px;
            }
        }

        .Navbar__bottom_img {
            position: absolute;
            top: -10px;
            right: 0;
        }

        .nav-link {
            padding: 5px;
            margin-bottom: 5px;
            // justify-content: flex-start !important;
        }

        .Navbar__dropdown_link.DropDownItem.nav-item.dropdown {
            justify-content: flex-start;
        }
    }

    &__logout {
        display: flex;
        align-items: center;
    }

    a.subNavItem.nav-link {
        width: 100%;
        background-color: transparent;
        padding: 0;
        padding-right: 10px;
    }

    .subNavItem .NavbarLogo {
        // justify-content: space-between;
        width: 100%;
        text-decoration: none;
        cursor: pointer;
        color: #869fb1;
        font-weight: 700;
        font-size: 13px;
        padding: 16px !important;
        padding-right: 0 !important;
    }
}

nav.Navbar.navbar.navbar-expand-lg.navbar-light {
    padding: 0px !important;
    position: sticky;
    top: 0;
    z-index: 9999;
}

.Navbar__dropdown_link.DropDownItem.nav-item.dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-nav .dropdown-menu {
    position: absolute !important;
}

.subNavItem {
    padding: 0;

    .NavbarLogo {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        margin: 0;
    }

    p.NavbarLogo::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        margin-right: 10px;
        margin-left: 5px;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .Navbar__logout {
        border-top: 0 !important;

        .Navbar__logoutBtn {
            border-top: 0 !important;
        }
    }

    .dropdown-menu.show div:last-child {
        // border-top: 1px solid #7793a7;
        padding-top: 5px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .navbar-expand-lg {
        .navbar-nav {
            align-items: center;
        }
    }

    .Navbar .Navbar__brand .Navbar__logo {
        width: 140px;
    }

    .Navbar__link > a {
        padding: 16px 11px !important;
    }

    .Navbar__bottom_img {
        margin-top: 0;
    }
}
