.Program_slider {
    width: 45% !important;

    &__rest_checkbox {
        margin: 5px;
    }

    &__upldImg {
        margin-top: 30px;
        height: 200px;
        width: 200px;
        border: 2px solid #ccc;
    }

    &__blockDisplay:hover {
        cursor: pointer;
    }

    &__tabDisAble {

        input,
        label {
            user-select: none !important;
            pointer-events: none !important;
        }
    }

    .fileInputWrapper {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .fileInput {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .uploadBtn {
        padding: 0 1rem;
        line-height: 36px;
        background: #27c24c;
        border: none;
        outline: none;
        color: #fff;
        cursor: pointer;
    }

    .nav-link {
        display: block;
        color: none;
        text-decoration: none;
    }

    .Program_slider__restCheckbox {
        border: 1px solid #ccc;
        margin-top: 12px;
        padding: 10px;
        width: 100%;
        height: 46%;
        overflow: scroll;
    }

    .Demo {
        display: flex;
        justify-content: flex-end;
    }

    ul.mb-3.nav.nav-tabs.nav-justified {
        background-color: #f1f1f1;
        border-radius: 0;

        .nav-link {
            color: #000;
            font-weight: 700;

            &.active {
                background-color: #ccc;
            }
        }
    }

    .tab-content {
        margin-top: 25px;

        table {
            tr {
                td {
                    button {
                        padding: 4px 8px;
                        margin: 8px;
                    }
                }
            }
        }
    }

    .serviceLabel {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    label {
        font-size: 14px;
        font-weight: 600;
        color: #000;
    }

    .serviceInputSec {
        label {
            color: #000;
            font-weight: 600;
            margin-top: 10px;
            font-size: 14px;
        }

        input {
            background-color: #fff;
        }
    }

    .donateSec {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            margin: 0 10px;

            input {
                background-color: #eee;
                border-radius: 5px !important;
                padding: 5px 8px;
            }
        }
    }
}

@media (max-width: 768px) {
    .Program_slider {
        width: 80% !important;

        .serviceLabel {
            margin-top: 25px;
            flex-direction: column;

            div {
                width: 100%;
                margin: 0 25px;
            }
        }

        ul.mb-3.nav.nav-tabs.nav-justified .nav-link {
            font-size: 14px;
        }
    }

    table {

        th,
        td {
            padding: 6px;
            font-size: 14px;
        }
    }
}

@media (min-width: 768px) and (max-width: 821px) {
    .Program_slider {
        width: 90% !important;
    }
}

@media (min-width: 820px) and (max-width: 1280px) {
    .Program_slider {
        width: 75% !important;
    }
}