.Inventory {
    $table-bg-color: #f2f2f2;
    $table-header-bg-color: #d9d9d9;
    $table-header-font-color: #333;
    $table-font-color: #444;

    .dropInventory {
        max-height: 200px;
        overflow-x: scroll;
    }

    &__tabTableImg {
        display: flex;

        table tbody tr td:first-child img {
            width: 50px;
            height: 50px px;
            object-fit: cover;
            margin: 5px;
        }

        &>* {
            margin: 0px 5px;
        }
    }

    &__asTabTableImg {
        display: flex;

        &>* {
            margin: 0px 5px;
        }
    }

    &__typeTableImg {
        width: 20px;
        height: 20px;
    }

    &__asTableImg {
        width: 20px;
        height: 20px;
    }

    &__buttonTable {
        display: flex;

        &>* {
            margin: 0px 5px;
        }

        padding: 2px;

        .Custom__button {
            padding: 5px 10px !important;

            i.fa-regular {
                margin-right: 4px;
            }
        }
    }

    &__accHolder {
        margin: 20px;

        &>* {
            margin: 10px;
        }

        .accordion-item {
            border-radius: 0 !important;
            border: 0;
        }

        button.accordion-button {
            flex-wrap: wrap;
        }

        button.accordion-button.collapsed {
            font-weight: bold;
            font-size: 16px;
            background-color: #f6f8f8;
            color: #58666e;

            &:hover {
                color: #141719;
            }
        }

        .custom-accordian .accordion-button:not(.collapsed) {
            font-weight: bold;
            font-size: 16px;
            color: #141719;
            background-color: #f6f8f8;
        }
    }

    &__row>div {
        margin: 10px;

        .CustomDropdown__header {
            font-weight: 400;
        }
    }

    .ContainerTypeClass {
        .inventryMenu {
            // text-overflow: ellipsis;
            overflow: scroll;
            height: 250px;
            width: 118% !important;

            .dropdown-item {
                white-space: pre-wrap;
                font-size: 12px;
            }
        }

        .containerTypeToggle {
            overflow: hidden;
            font-size: 12px;
        }
    }

    .Inventory__dbDown {
        padding: 10px;
    }

    &__dbDown>* {
        margin: 8px;
    }

    p.Inventory__paraHeaderFirst {
        margin-left: 10px;
        margin-bottom: 5px;
        font-weight: 500;
        color: #62686e;
        font-size: 14px;
        margin-top: 8px;
    }

    p.Inventory__paraHeaderSec {
        margin-left: 10px;
        margin-bottom: 5px;
        font-weight: 500;
        color: #62686e;
        font-size: 14px;
        margin-top: 8px;
    }

    .react-datepicker-wrapper {
        margin: 0;
        margin-left: 10px;

        input.month {
            padding: 5px;
            border: 1px solid #ccc;
            // border-radius: 5px;
            width: calc(100% - 8%);

            &:focus-within {
                box-shadow: 0;
                outline: 0;
            }
        }
    }

    &__btnAddBox {
        color: #ffffff !important;
        background-color: #1c2b36;
        border-color: #1c2b36;
        border: none;
        border-radius: 0.2rem;
        padding: 5px;
        margin-left: 5px;
    }

    &__rangeSec {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;

        &>div {
            margin: 5px;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        background-color: $table-bg-color;
        border: 0;

        th,
        td {
            text-align: left;
            padding: 12px;
            border: 1px solid #ddd;
            font-size: 16px;
        }

        th {
            background-color: $table-header-bg-color;
            color: $table-header-font-color;
            font-weight: bold;
            text-transform: uppercase;
            border-bottom: 2px solid #ddd;

            .downArrow {
                width: 10px;
                margin: 0 10px;
            }

            .theadTitleContent {
                display: flex;
                align-items: center;
                justify-content: center;

                .theadTitle {
                    width: max-content;
                    margin: 0;
                }

                span {
                    width: 15px;
                }
            }
        }

        tbody tr {
            &:nth-child(even) {
                background-color: #f2f2f2;
            }

            &:hover {
                background-color: #e5e5e5;
            }

            td {
                font-weight: 600;
                color: #58666e !important;
                border: 0;
                font-size: 13px;
            }

            td:last-child {
                text-transform: capitalize;
            }
        }

        @media screen and (max-width: 600px) {

            th,
            td {
                padding: 6px;
                font-size: 14px;
            }
        }
    }

    &__searchSection>div {
        margin: 0 20px;

        input#email:focus {
            box-shadow: none;
            border-color: #cfd5db;
        }
    }
}

@media (max-width: 768px) {

    .Inventory table th,
    .Inventory table td {
        font-size: 12px;
    }

    .Inventory .react-datepicker-wrapper input.month {
        width: 95%;
    }
}

@media (min-width: 768px) and (max-width: 992px) {

    .Inventory table th,
    .Inventory table td {
        font-size: 12px;
    }

    .inventoryCommon {
        flex-direction: column;
        width: 100%;

        .Inventory__dbDown {
            width: 100%;
        }
    }
}