.restaurantSection {
    .searchFieldSec {
        display: flex;
        padding: 0px 14px;

        div {
            width: 100%;
            // margin-top: 25px !important;
            margin: 10px;
        }

        .dropdown-menu {
            overflow: auto;
            max-height: 350px;
        }
    }

    .Resturant_category {
        width: max-content;

        .infoIcon {
            background-color: #75cc34;
            padding: 6px 8px;
            margin: 0 3px;
            cursor: pointer;
            border-radius: 4px;
            cursor: pointer;
            cursor: pointer;
            position: relative;

            &:hover {
                background-color: red;
                color: #fff;
            }

            .tooltiptext {
                visibility: hidden;
                width: 80px;
                background-color: rgb(166, 163, 163);
                color: #fff;
                text-align: center;
                padding: 4px 0;
                font-size: 11px;
                position: absolute;
                top: 30px;
                z-index: 1;
            }

            &:hover .tooltiptext {
                visibility: visible;
            }
        }

        .recycleIcon {
            background-color: #fad733;
            padding: 6px 8px;
            margin: 0 3px;
            cursor: pointer;
            cursor: pointer;
            position: relative;

            .tooltiptext {
                visibility: hidden;
                width: 80px;
                background-color: rgb(166, 163, 163);
                color: #fff;
                text-align: center;
                padding: 4px 0;
                font-size: 11px;
                position: absolute;
                top: 30px;
                z-index: 1;
            }

            &:hover .tooltiptext {
                visibility: visible;
            }
        }

        .deleteIcon {
            background-color: #ea2222;
            border-color: #ea2222;
            padding: 6px 8px;
            margin: 0 3px;
            border-radius: 4px;
            cursor: pointer;
            position: relative;

            .tooltiptext {
                visibility: hidden;
                width: 80px;
                background-color: rgb(166, 163, 163);
                color: #fff;
                text-align: center;
                padding: 4px 0;
                font-size: 11px;
                position: absolute;
                top: 30px;
                z-index: 1;
            }

            &:hover .tooltiptext {
                visibility: visible;
            }
        }
    }

    .Custom__button {
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        padding: 4px 10px;
    }

    table td {
        color: #58666e;
        border-top: 0;
        border-bottom: 0;
        padding-top: 10px;
        vertical-align: top;
    }

    table tr:nth-child(even) {
        background-color: #f3f3f3;
    }

    i.fa-solid {
        color: #333333;
    }
}

.Resturant_State {
    text-overflow: ellipsis;
}

table tbody tr td:first-child {
    img {
        width: 50px;
        height: 50px;
    }
}

.restaurantTableSec {
    // .elipsisCs {
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     max-width: 80px;
    // }

    .theadTitleContent p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 135px;
    }
}

.customFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    input {
        font-size: 14px !important;
    }

    .searchIcon {
        position: absolute;
        top: 22px;
        right: 22px;
        color: #58666e;
    }
}

.inputField {
    width: 95%;
    border: 1px solid #dee5e7 !important;
    font-size: 14px;
    // margin-bottom: 10px;
    font-weight: 600 !important;
    padding: 5px 6px !important;
    cursor: unset;
}

.userDropdown {
    button {
        width: 95% !important;
        border: 1px solid #dee5e7 !important;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: bold;
        padding: 5px 6px !important;
        cursor: unset !important;
    }
}

.tablePagination {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px;

    .itemPagination {
        display: flex;
    }

    .perPageItem {
        p {
            margin: 0;
            color: gray;
        }
    }
}

@media (max-width: 768px) {
    .restaurantSection {
        .searchFieldSec {
            .SearchCustomInput {
                margin: 0 7px;
                margin-top: 10px;
            }

            flex-direction: column;

            div {
                padding: 3px 7px;
                margin: 0;
            }
        }
    }

    .userDropdown {
        button {
            width: 100% !important;
        }
    }

    .customFlex .searchIcon {
        top: 24px;
        right: 28px;
        font-size: 14px;
        /* font-weight: bold; */
        color: rgb(85, 85, 85);
    }
}

@media (min-width: 820px) and (max-width: 1368px) {
    .restaurantSection {
        .restaurantTableSec {
            min-height: 82vh;
        }
    }
}
