.OrderSection {
    .searchFieldSec {
        display: flex;
    }
    .DateAndDropdown {
        display: flex;
        align-items: baseline;
        padding-top: 15px;
        .Status_Section {
            width: 22%;
        }
    }

    .OrderDateSelect {
        // margin: 6px 25px;
        width: 22%;
        margin-left: 1rem;

        .react-datepicker-wrapper {
            input {
                color: #58666e;
                background-color: #fff;
                border: 1px solid #ccc;
                width: 100%;
                border-radius: 0;
                padding: 7px;
            }
        }
    }
}

.orderTable {
    .NewBtn {
        text-align: center;
        color: #333dff;
        border: 2px solid #333fdd;
    }
    .inprocessBtn {
        background: #fff;
        color: #f5977d;
        border: 2px solid #f5977d;
        text-align: center;
    }

    .deliverBtn {
        color: #eb1d5d;
        text-align: center;
        border: 2px solid #eb1d5d;
        padding: 0 5px;
    }

    .shippedBtn {
        color: #bc04b7;
        text-align: center;
        border: 2px solid #bc04b7;
    }

    .invoicedBtn {
        color: #00afec;
        text-align: center;
        border: 2px solid #00afec;
    }

    .paidBtn {
        color: #00a453;
        text-align: center;
        border: 2px solid #00a453;
    }

    .orderDetailbtn {
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
        padding: 4px 10px;
        margin: 5px;
        border-radius: 0.2rem;
        border: none;
        background-color: #1c2b36;
        border-color: #1c2b36;
        border: 0;
        width: max-content;

        &:focus {
            background-color: #1c2b36;
            border-color: #1c2b36;
        }

        .infoIcon {
            margin-right: 5px;
            font-size: 16px;
        }
    }
    table td {
        color: #58666e;
        border-top: 0;
        border-bottom: 0;
    }
    table tr:nth-child(even) {
        background-color: #f3f3f3;
    }
}

@media (max-width: 768px) {
    .OrderSection {
        .DateAndDropdown {
            display: block;

            .OrderDateSelect {
                width: 98%;
                margin-left: 1rem;
                padding: 10px 20px;
            }

            .Status_Section {
                width: 100%;
            }
        }
    }
    .orderTable {
        table {
            tbody {
                td {
                    div {
                        width: max-content;
                        padding: 1px 9px;
                    }
                }
            }
        }
    }

    .pagination_section .table__pagination__container ul li a {
        padding: 2px 8px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .OrderSection {
        .Status_Section {
            width: 100%;
            padding-left: 33px;
        }
    }
}

@media (min-width: 818px) and (max-width: 1372px) {
    .custmizeOrdeTable {
        min-height: 80vh;
    }
}
