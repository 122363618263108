.tableDiv {
    padding: 10px;
}

.SidebarSec {
    display: flex;
    background-color: #f0efef;
    margin-bottom: 4rem;

    .dashboardSec {
        align-items: center;

        .apexcharts-menu-icon {
            display: none;
        }
    }

    .hbox {
        display: table;
        width: 100%;
        border-spacing: 0;
        table-layout: fixed;
        background-color: #f0efef;

        .chart_div {
            background-color: white;
            overflow: scroll;
            min-height: 400px;

            .textMsg {
                font-size: 14px;
                margin: 10px;
                padding: 10px;
                color: #626161;
            }

        }

        &__panel-heading {
            color: #333;
            background-color: #f5f5f5;
            border-color: #ddd;
            border-radius: 2px 2px 0 0;
            padding: 10px 15px;
            border-bottom: 1px solid transparent;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            margin: 0px;

            p {
                margin: 0px;
                font-size: 14px;
            }
        }

        .font_bold {
            font-weight: 600;
        }

        .onboard_User_details {
            p {
                padding: 20px 20px 0px 40px;
                color: black;
                text-decoration: underline;
                font-size: 13px;
            }

            .onboard_User_details_subheading {
                p {
                    padding: 0 0px 0px 40px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}