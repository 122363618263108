.Content {
    .no-available {
        align-items: center;
        justify-content: center;

        .noContentAvailable {
            // min-height: 78vh;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .main_sections {
            text-align: center;
            color: #31708f;
            background-color: #d9edf7;
            border-color: #bce8f1;
            padding: 15px;
            border-radius: 5px;
            width: 100%;
        }
    }
}
