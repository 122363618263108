$table-bg-color: #f2f2f2;
$table-header-bg-color: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0, #eee),
        color-stop(1, #fff));
$table-header-font-color: #333;
$table-font-color: #58666e;

.ReportComponent {
    .reportSearch {
        padding: 10px;
        margin: 15px 0;

        .reportDateSelect {
            input.month {
                width: 100%;
                border: 1px solid #ccc;
                font-size: 15px;
                margin-bottom: 10px;
                font-weight: 500;
                color: rgb(85, 85, 85);
                padding: 5px 12px;

                &:focus-within {
                    outline: 0;
                }
            }
        }

        .Inventory__buttonTable .Custom__button {
            padding: 3px 9px !important;
            margin: 3px;

            i.fa-solid {
                margin-right: 5px;
            }
        }

        table td {
            color: #58666e;
            border-top: 0;
            border-bottom: 0;
        }

        table tr:nth-child(even) {
            background-color: #f3f3f3;
        }
    }
}

.ReportModal {
    justify-content: flex-end !important;
}

.Inventory__buttonTable {
    .info {
        padding: 4px;
    }
}

.custmizeReportTable {
    table {
        tr {
            td {
                div {
                    width: max-content;
                }
            }
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    background-color: $table-bg-color;
    border: 1px solid #ddd;

    th,
    td {
        text-align: left;
        padding: 2px 10px;
        border: 1px solid #ddd;
        font-size: 14px;
        color: #58666e;
    }

    th {
        background: $table-header-bg-color;
        color: $table-header-font-color;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 2px solid #ddd;
        padding: 7px;
        text-transform: capitalize;
        font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;

        .downArrow {
            width: 10px;
            margin: 0 10px;
            position: relative;
            top: -1px;
        }

        .theadTitleContent {
            display: flex;
            align-items: center;
            // justify-content: center;

            .theadTitle {
                text-transform: capitalize;

                width: max-content;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 185px;
                color: #58666e;
                font-size: 13px;
            }

            span {
                width: 15px;
            }
        }
    }

    tbody tr {
        background-color: white;

        &:hover {
            background-color: #e5e5e5;
        }

        td:first-child {
            color: $table-font-color;
        }

        td:last-child {
            text-transform: capitalize;
        }
    }

    @media screen and (max-width: 600px) {
        table th .theadTitleContent .theadTitle {
            width: max-content;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80px;
            color: #58666e;
            font-size: 13px;
        }

        th,
        td {
            padding: 6px;
            font-size: 14px;
        }
    }
}

.report_sec {
    color: #333;
    padding: 6px;
    background-color: #75cc34;
    border-color: #78cd33;
}

@media (max-width: 1370px) {
    .reportSearch {
        .custmizeReportTable {
            min-height: 82vh;
        }
    }
}