.ModalHead {
    width: 32% !important;
    .Report_Modal_Header {
        font-weight: 400;
        font-size: x-large;
    }
    .orderModalTable {
        th.Demo {
            font-size: smaller;
            font-weight: 500;
        }
        .firstCl {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            .ReportSlider_img {
                width: 40%;
            }
            ._id {
                padding-left: 10px;
            }
        }
    }
}
@media (max-width: 768px) {
    .ModalHead {
        width: 90% !important;
        .Report_Modal_Header {
            font-size: 20px;
        }
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .ModalHead {
        width: 70% !important;
    }
}
