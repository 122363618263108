.rewardQr {
    display: flex;

    table {
        background-color: none;
        border: none;
    }

    &__table {
        margin: 0;
        text-align: center;
        margin-top: 25px;
    }

    &__second {
        margin-top: 10px;
    }

    td {
        background: none !important;
        border: none;
    }
}
@media (max-width: 768px) {
    .rewardQr {
        flex-direction: column;
    }
}
